import api from './utilBackoffice';

const controller = 'ComissionamentoSplitTC';
const url = process.env.VUE_APP_ROOT_API + controller;

export default Object.assign({}, api.GetCrud(controller, null), {
  Listar: dto =>
    api.Axios.GetParams(url + '/ListarComissionamentoSplitTC', dto),
  Create: newDto => api.Axios.Post(url, newDto),
  Update: newDto => api.Axios.Post(url + '/Atualiza', newDto),
  Delete: newDto => api.Axios.Post(url + '/Delete', newDto)
});
