import api from './utilBackoffice';

const controller = 'ListaServicoComissionamento';
const url = process.env.VUE_APP_ROOT_API + controller;

export default Object.assign({}, api.GetCrud(controller, null), {
  Listar: dto => api.Axios.GetParams(url + '/Listar', dto),
  Create: dto => api.Axios.Post(url, dto),
  Update: dto => api.Axios.Post(url + '/Atualiza', dto),
  Delete: dto => api.Axios.Post(url + '/Delete', dto)
});
