import api from './utilBackoffice';

const controller = 'TipoContratoDetalhe';
const url = process.env.VUE_APP_ROOT_API + controller;
export default Object.assign({}, api.GetCrud(controller, null), {
  Listar: dto => {
    return api.Axios.GetParams(url, dto);
  },
  Create: dto => {
    return api.Axios.Post(url, dto);
  },
  Update: dto => {
    return api.Axios.Post(url + '/Atualiza', dto);
  },
  Delete: id => {
    return api.Axios.Post(url + '/delete', id);
  }
});
