export default [
  {
    path: '/dashboard',
    view: 'Dashboard',
    dir: 'views/app/',
    icon: 'fas fa-home',
    title: 'Dashboard',
    menu: true,
    meta: {
      roles: ['Safe.BackOffice.User'],
      allRolesRequired: false
    }
  },
  {
    path: '/cadoc',
    dir: 'views/app/Cadoc',
    icon: 'fas fa-sticky-note',
    title: 'CADOC',
    menu: true,
    meta: {
      roles: ['Safe.BackOffice.User'],
      allRolesRequired: false
    },
    items: [
      {
        path: '/remessa',
        title: 'Remessa',
        view: 'Remessa',
        icon: '',
        dir: '/Remessa/',
        menu: true,
        meta: {
          roles: ['Safe.BackOffice.User'],
          allRolesRequired: false
        }
      }
    ]
  },
  {
    path: '/parametros',
    dir: 'views/app/Parametros',
    icon: 'fas fa-wrench',
    title: 'Parâmetros Bacen',
    menu: true,
    meta: {
      roles: ['Safe.BackOffice.User'],
      allRolesRequired: false
    },
    items: [
      {
        path: '/parametro-cadoc',
        title: 'Parâmetros Cadoc 3040',
        icon: '',
        dir: '/ParametrosCadoc/',
        view: 'ParametroCadoc',
        menu: true,
        meta: {
          roles: ['Safe.BackOffice.User'],
          allRolesRequired: false
        }
      },
      {
        path: '/parametros-gerais',
        title: 'Demais Parâmetros',
        icon: '',
        dir: '/DemaisParametros',
        menu: true,
        meta: {
          roles: ['Safe.BackOffice.User'],
          allRolesRequired: false
        },
        items: [
          {
            path: '/tipo-identidade-exterior',
            title: 'Tipo Identidade Exterior',
            icon: '',
            dir: '/TipoIdentidadeExterior/',
            view: 'TipoIdentidadeExterior',
            menu: true,
            meta: {
              roles: ['Safe.BackOffice.User'],
              allRolesRequired: false
            }
          },
          {
            path: '/natureza-operacao',
            title: 'Natureza Operação',
            icon: '',
            dir: '/NaturezaOperacao/',
            view: 'NaturezaOperacao',
            menu: true,
            meta: {
              roles: ['Safe.BackOffice.User'],
              allRolesRequired: false
            }
          },
          {
            path: '/classificacao-risco-cliente',
            title: 'Classificação Risco Cliente',
            icon: '',
            dir: '/ClassificacaoRiscoCliente/',
            view: 'ClassificacaoRiscoCliente',
            menu: true,
            meta: {
              roles: ['Safe.BackOffice.User'],
              allRolesRequired: false
            }
          },
          {
            path: '/classificacao-risco-operacao',
            title: 'Classificação Risco Operação',
            icon: '',
            dir: '/ClassificacaoRiscoOperacao/',
            view: 'ClassificacaoRiscoOperacao',
            menu: true,
            meta: {
              roles: ['Safe.BackOffice.User'],
              allRolesRequired: false
            }
          },
          {
            path: '/faixa-valores',
            title: 'Faixa de Valores',
            icon: '',
            dir: '/FaixaValores/',
            view: 'FaixaValores',
            menu: true,
            meta: {
              roles: ['Safe.BackOffice.User'],
              allRolesRequired: false
            }
          },
          {
            path: '/modalidade-operacao',
            title: 'Modalidade Operação',
            icon: '',
            dir: '/ModalidadeOperacao/',
            view: 'ModalidadeOperacao',
            menu: true,
            meta: {
              roles: ['Safe.BackOffice.User'],
              allRolesRequired: false
            }
          },
          {
            path: '/origem-recurso',
            title: 'Origem Recurso',
            icon: '',
            dir: '/OrigemRecurso/',
            view: 'OrigemRecurso',
            menu: true,
            meta: {
              roles: ['Safe.BackOffice.User'],
              allRolesRequired: false
            }
          },
          {
            path: '/porte-pessoa-pf',
            title: 'Porte Pessoa PF',
            icon: '',
            dir: '/PortePessoaPF/',
            view: 'PortePessoaPF',
            menu: true,
            meta: {
              roles: ['Safe.BackOffice.User'],
              allRolesRequired: false
            }
          },
          {
            path: '/porte-pessoa-pj',
            title: 'Porte Pessoa PJ',
            icon: '',
            dir: '/PortePessoaPJ/',
            view: 'PortePessoaPJ',
            menu: true,
            meta: {
              roles: ['Safe.BackOffice.User'],
              allRolesRequired: false
            }
          },
          {
            path: '/responsabilidade',
            title: 'Responsabilidade',
            icon: '',
            dir: '/Responsabilidade/',
            view: 'Responsabilidade',
            menu: true,
            meta: {
              roles: ['Safe.BackOffice.User'],
              allRolesRequired: false
            }
          },
          {
            path: '/tipo-controle',
            title: 'Tipo Controle',
            icon: '',
            dir: '/TipoControle/',
            view: 'TipoControle',
            menu: true,
            meta: {
              roles: ['Safe.BackOffice.User'],
              allRolesRequired: false
            }
          },
          {
            path: '/indexador',
            title: 'Indexador',
            icon: '',
            dir: '/Indexador/',
            view: 'Indexador',
            menu: true,
            meta: {
              roles: ['Safe.BackOffice.User'],
              allRolesRequired: false
            }
          },
          {
            path: '/tipo-uso-regulatorio',
            title: 'Tipo Uso Regulatório',
            icon: '',
            dir: '/TipoUsoRegulatorio/',
            view: 'TipoUsoRegulatorio',
            menu: true,
            meta: {
              roles: ['Safe.BackOffice.User'],
              allRolesRequired: false
            }
          },
          {
            path: '/caracteristica-especial',
            title: 'Característica Especial',
            icon: '',
            dir: '/CaracteristicaEspecial/',
            view: 'CaracteristicaEspecial',
            menu: true,
            meta: {
              roles: ['Safe.BackOffice.User'],
              allRolesRequired: false
            }
          },
          {
            path: '/garantia',
            title: 'Garantia',
            icon: '',
            dir: '/Garantia/',
            view: 'Garantia',
            menu: true,
            meta: {
              roles: ['Safe.BackOffice.User'],
              allRolesRequired: false
            }
          },
          {
            path: '/tipo-instrumento',
            title: 'Tipo Instrumento',
            icon: '',
            dir: '/TipoInstrumento/',
            view: 'TipoInstrumento',
            menu: true,
            meta: {
              roles: ['Safe.BackOffice.User'],
              allRolesRequired: false
            }
          }
        ]
      }
    ]
  },
  {
    path: '/cadastros',
    title: 'Cadastros',
    dir: 'views/app/Cadastros/',
    icon: 'fa-solid fa-folder',
    menu: true,
    meta: {
      roles: ['Safe.BackOffice.User'],
      allRolesRequired: false
    },
    items: [
      {
        path: '/comissionamento',
        title: 'Comissionamento',
        icon: '',
        dir: 'Comissionamento/',
        view: 'Comissionamento',
        menu: true,
        meta: {
          roles: ['Safe.BackOffice.User'],
          allRolesRequired: false
        }
      },
      {
        path: '/conta-cr',
        title: 'Conta CR',
        icon: '',
        dir: 'ContaCR/',
        view: 'ContaCR',
        menu: true,
        meta: {
          roles: ['Safe.BackOffice.User'],
          allRolesRequired: false
        }
      },
      {
        path: '/lista-servico-comissionamento',
        title: 'Serviço Comissionamento',
        icon: '',
        dir: 'ListaServicoComissionamento/',
        view: 'ListaServicoComissionamento',
        menu: true,
        meta: {
          roles: ['Safe.BackOffice.User'],
          allRolesRequired: false
        }
      },
      {
        path: '/evento-contabil',
        title: 'Evento Contábil',
        icon: '',
        dir: 'EventoContabil/',
        view: 'Contabil',
        menu: true,
        meta: {
          roles: ['Safe.BackOffice.User'],
          allRolesRequired: false
        }
      },
      {
        path: '/evento-contabil-geral',
        title: 'Evento Contábil Geral',
        icon: '',
        dir: 'EventoContabilGeral/',
        view: 'EventoContabilGeral',
        menu: true,
        meta: {
          roles: ['Safe.BackOffice.User'],
          allRolesRequired: false
        }
      },
      {
        path: '/evento-contabil-recebimento',
        title: 'Evento Contábil Recebimento',
        icon: '',
        dir: 'EventoContabilRecebimento/',
        view: 'EventoContabilRecebimento',
        menu: true,
        meta: {
          roles: ['Safe.BackOffice.User'],
          allRolesRequired: false
        }
      },
      {
        path: '/operador',
        title: 'Operador',
        dir: 'Operador/',
        view: 'Operador',
        menu: true,
        meta: {
          roles: ['Safe.BackOffice.User'],
          allRolesRequired: false
        }
      },
      {
        path: '/feriado-extra',
        title: 'Feriados Extras',
        icon: '',
        dir: 'FeriadoExtra/',
        view: 'FeriadoExtra',
        menu: true,
        meta: {
          roles: ['Safe.BackOffice.User'],
          allRolesRequired: false
        }
      },
      {
        path: '/controle-nivel-risco',
        title: 'Controle Nível Risco',
        icon: '',
        dir: 'ControleNivelRisco/',
        view: 'ControleNivelRisco',
        menu: true,
        meta: {
          roles: ['Safe.BackOffice.User'],
          allRolesRequired: false
        }
      },
      {
        path: '/restricao-cadoc',
        title: 'Restrição CADOC',
        icon: '',
        dir: 'RestricaoCADOC/',
        view: 'RestricaoCADOC',
        menu: true,
        meta: {
          roles: ['Safe.BackOffice.User'],
          allRolesRequired: false
        }
      },
      {
        path: '/grupo-produto-bacen',
        title: 'Grupo Produto BACEN',
        icon: '',
        dir: 'GrupoProdutoBACEN/',
        view: 'GrupoProdutoBACEN',
        menu: true,
        meta: {
          roles: ['Safe.BackOffice.User'],
          allRolesRequired: false
        }
      },
      {
        path: '/tipo-contrato-detalhe',
        title: 'Tipo Contrato Detalhe',
        icon: '',
        dir: 'TipoContratoDetalhe/',
        view: 'TipoContratoDetalhe',
        menu: true,
        meta: {
          roles: ['Safe.BackOffice.User'],
          allRolesRequired: false
        }
      },
      {
        path: '/contratos-renegociacao',
        title: 'Contratos Renegociação',
        icon: '',
        dir: 'ContratosRenegociacao/',
        view: 'ContratosRenegociacao',
        menu: true,
        meta: {
          roles: ['Safe.BackOffice.User'],
          allRolesRequired: false
        }
      }
    ]
  },
  {
    path: '/movimentos-contabeis',
    title: 'Consultas',
    dir: 'views/app/Consultas/',
    icon: 'fa fa-search',
    menu: true,
    meta: {
      roles: ['Safe.BackOffice.User'],
      allRolesRequired: false
    },
    items: [
      {
        path: '/movimento-contabil',
        title: 'Movimento Contábil',
        icon: '',
        dir: 'MovimentoContabil/',
        view: 'MovimentoContabil',
        menu: true,
        meta: {
          roles: ['Safe.BackOffice.User'],
          allRolesRequired: false
        }
      },
      {
        path: '/eventos-contabeis-cadastrados',
        title: 'Eventos Contábeis Cadastrados',
        icon: '',
        dir: 'EventoContabil/',
        view: 'EventoContabilCadastrado',
        menu: true,
        meta: {
          roles: ['Safe.BackOffice.User'],
          allRolesRequired: false
        }
      },
      {
        path: '/proposta',
        title: 'Propostas Pagas',
        icon: '',
        dir: 'Proposta/',
        view: 'Proposta',
        menu: true,
        meta: {
          roles: ['Safe.BackOffice.User'],
          allRolesRequired: false
        }
      },
      {
        path: '/proposta-cancelada',
        title: 'Propostas Canceladas',
        dir: 'PropostaCancelada/',
        view: 'PropostaCancelada',
        menu: true,
        meta: {
          roles: ['Safe.BackOffice.User'],
          allRolesRequired: false
        }
      },
      {
        path: '/proposta-pendente-pagamento',
        title: 'Propostas Pendente Pagamento',
        dir: 'PropostaPendentePagamento/',
        view: 'PropostaPendentePagamento',
        menu: true,
        meta: {
          roles: ['Safe.BackOffice.User'],
          allRolesRequired: false
        }
      },
      {
        path: '/saldo-contrato',
        title: 'Saldo Contrato',
        icon: '',
        dir: 'SaldoContrato/',
        menu: true,
        meta: {
          roles: ['Safe.BackOffice.User'],
          allRolesRequired: false
        },
        items: [
          {
            path: '/saldo-contrato-analitico',
            title: 'Relatório Analítico',
            icon: '',
            dir: 'SaldoContratoAnalitico/',
            view: 'SaldoContratoAnalitico',
            menu: true,
            meta: {
              roles: ['Safe.BackOffice.User'],
              allRolesRequired: false
            }
          },
          {
            path: '/saldo-contrato-sintetico',
            title: 'Relatório Sintético',
            icon: '',
            dir: 'SaldoContratoSintetico/',
            view: 'SaldoContratoSintetico',
            menu: true,
            meta: {
              roles: ['Safe.BackOffice.User'],
              allRolesRequired: false
            }
          }
        ]
      },
      {
        path: '/saldo-parcela',
        title: 'Saldo Parcela',
        icon: '',
        dir: 'SaldoParcela/',
        view: 'SaldoParcela',
        menu: true,
        meta: {
          roles: ['Safe.BackOffice.User'],
          allRolesRequired: false
        }
      },
      {
        path: '/historico-operacao',
        title: 'Histórico Operações',
        icon: '',
        dir: 'HistoricoOperacao/',
        view: 'HistoricoOperacao',
        menu: true,
        meta: {
          roles: ['Safe.BackOffice.User'],
          allRolesRequired: false
        }
      },
      {
        path: '/historico-base-calculo',
        title: 'Histórico Base Cálculo',
        icon: '',
        dir: 'HistoricoBaseCalculo/',
        view: 'HistoricoBaseCalculo',
        menu: true,
        meta: {
          roles: ['Safe.BackOffice.User'],
          allRolesRequired: false
        }
      },
      {
        path: '/remessa-pagamento',
        title: 'Remessa Pagamento',
        icon: '',
        dir: 'RemessaPagamento/',
        view: 'RemessaPagamento',
        menu: true,
        meta: {
          roles: ['Safe.BackOffice.User'],
          allRolesRequired: false
        }
      },
      {
        path: '/transferencia-prejuizo',
        title: 'Transferência Prejuízo',
        icon: '',
        dir: 'TransferenciaPrejuizo/',
        view: 'TransferenciaPrejuizo',
        menu: true,
        meta: {
          roles: ['Safe.BackOffice.User'],
          allRolesRequired: false
        }
      },
      {
        path: '/iof',
        title: 'IOF',
        icon: '',
        dir: 'IOF/',
        view: 'IOF',
        menu: true,
        meta: {
          roles: ['Safe.BackOffice.User'],
          allRolesRequired: false
        }
      },
      {
        path: '/pdd',
        title: 'PDD',
        icon: '',
        dir: 'PDD/',
        view: 'PDD',
        menu: true,
        meta: {
          roles: ['Safe.BackOffice.User'],
          allRolesRequired: false
        }
      }
    ]
  },
  {
    path: '/ajustar',
    title: 'Ajustar',
    dir: 'views/app/Ajustar/',
    icon: 'fas fa-pencil-alt',
    menu: true,
    meta: {
      roles: ['Safe.BackOffice.User'],
      allRolesRequired: false
    },
    items: [
      {
        path: '/movimentos-pendentes',
        title: 'Movimentos Pendentes',
        icon: '',
        dir: 'MovimentoPendente/',
        view: 'MovimentoPendente',
        menu: true,
        meta: {
          roles: ['Safe.BackOffice.User'],
          allRolesRequired: false
        }
      },
      {
        path: '/cadoc',
        title: 'CADOC',
        icon: '',
        dir: 'AjusteCadoc/',
        view: 'AjusteCadoc',
        menu: true,
        meta: {
          roles: ['Safe.BackOffice.User'],
          allRolesRequired: false
        }
      }
    ]
  },
  {
    path: '/empresa',
    view: 'Empresa',
    dir: 'views/',
    title: 'Empresa',
    menu: false,
    meta: {
      anonymousAuth: true,
      roles: ['Safe.BackOffice.User'],
      allRolesRequired: true
    }
  },
  {
    path: '/',
    view: 'Login',
    dir: 'views/',
    title: 'Login',
    menu: false,
    meta: {
      anonymousAuth: true,
      roles: ['Safe.BackOffice.User'],
      allRolesRequired: true
    }
  },
  {
    path: '/senha/lembrar',
    view: 'Lembrar',
    dir: 'views/',
    title: 'Lembrar',
    menu: false,
    meta: {
      anonymousAuth: true,
      roles: ['Safe.BackOffice.User'],
      allRolesRequired: true
    }
  },
  {
    path: '/senha/recuperar',
    view: 'Recuperar',
    dir: 'views/',
    title: 'Recuperar',
    params: true,
    menu: false,
    meta: {
      anonymousAuth: true,
      roles: ['Safe.BackOffice.User'],
      allRolesRequired: true
    }
  },
  {
    path: '/reprocessamento',
    title: 'Reprocessamento',
    dir: 'views/app/Reprocessamento/',
    icon: 'fa-solid fa-window-restore',
    menu: true,
    meta: {
      roles: ['Safe.BackOffice.User'],
      allRolesRequired: false
    },
    items: [
      {
        path: '/movimentos-contabeis',
        title: 'Movimentos Contabeis',
        icon: '',
        dir: 'MovimentosContabeis/',
        view: 'MovimentosContabeis',
        menu: true,
        meta: {
          roles: ['Safe.BackOffice.User'],
          allRolesRequired: false
        }
      }
    ]
  }
];
